import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import ConfirmDialog from '@/view/pages/ConfirmDialog';
import ApiService from '@/core/services/api.service';

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: 'shop',
  data() {
    return {
      shop: {
        email: '',
        vatNumber: '',
        taxIdNumber: '',
        pin: '',
        bankAccNumber: '',
        clearanceLevel: null,
        phoneNumber: '',
        name: '',
        zipCode: ''
      },
      clearanceLevels: [
        { name: this.$i18n.t('shopUpdate.lvl1'), id: 1 },
        { name: this.$i18n.t('shopUpdate.lvl2'), id: 2 },
        { name: this.$i18n.t('shopUpdate.lvl3'), id: 3 }
      ],
      requiredRule: [v => !!v || this.$i18n.t('common.required')],
      isSaving: false,
      loading: false,
      shopId: null,
      shopName: ''
    };
  },
  async created() {
    this.loading = true;
    this.shopId = this.$route.params.shopId;
    this.shopName = this.$route.params.shopName;
    this.$log.debug('shopid: ', this.shopId);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$i18n.t('shopUpdate.details') + this.shopName }
    ]);
    this.retrieveShopDetails(this.shopId);
    this.loading = false;
  },

  methods: {
    retrieveShopDetails(shopId) {
      if (shopId != null) {
        this.loading = true;
        ApiService.get(`api/shops/${shopId}`)
          .then(response => {
            this.$log.debug('Shop: ', response.data);
            this.shop = response.data;
            this.showShopDialog = true;
          })
          .catch(error => {
            this.$log.error('Error: ', error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        ApiService.put(`api/shops`, this.shop)
          .then(response => {
            this.$log.debug('Shop updated: ' + response);
          })
          .catch(error => {
            this.$log.error('Error: ', error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  components: {
    ConfirmDialog
  },
  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', value);
        }
      }
    }
  }
};
